<template>
  <section>
    <form @submit.prevent="submitData">
      <div class="mb-3" style="display: none">
        <label for="inputEmailHomepage" class="form-label">
          {{ $t('upload.labelInputEmailHomepage') }}
        </label>
        <input
          v-model="honeypotValue"
          type="text"
          class="form-control"
          id="inputEmailHomepage"
          autocomplete="off"
          tabindex="-1" />
      </div>

      <div class="mb-3">
        <label for="inputEmail" class="form-label">
          {{ $t('upload.labelInputEmail') }}
        </label>
        <input
          v-model="email"
          type="email"
          class="form-control"
          id="inputEmail"
          aria-describedby="emailHelp" />

        <div id="emailHelp" class="form-text">
          {{ $t('upload.emailHelp') }}
        </div>
      </div>

      <div class="mb-3">
        <label for="inputFile" class="form-label">
          {{ $t('upload.labelInputFile') }}
        </label>

        <input
          type="file"
          ref="inputFile"
          class="form-control"
          id="inputFile"
          accept="text/xml"
          @change="onFileChange" />
      </div>

      <div v-if="progress" class="progress mb-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: progress + '%' }"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>

      <div class="mb-3 form-check">
        <input
          v-model="newsletter"
          type="checkbox"
          class="form-check-input"
          id="checkboxNewsletter" />
        <label class="form-check-label" for="checkboxNewsletter">
          {{ $t('upload.labelNewsletter') }}
        </label>
      </div>

      <div class="mb-3 form-check">
        <input
          v-model="gtc"
          type="checkbox"
          class="form-check-input"
          id="checkboxGtc" />

        <label class="form-check-label" for="checkboxGtc">
          {{ $t('upload.labelGtc') }}
        </label>
      </div>

      <button
        :disabled="!submitReady"
        type="submit"
        class="btn btn-primary me-2">
        {{ $t('upload.buttonStart') }}
      </button>

      <button
        :disabled="!submitStarted"
        type="button"
        class="btn btn-secondary me-2"
        @click.prevent="cancleUpload">
        {{ $t('upload.buttonCancle') }}
      </button>

      <button
        :disabled="submitStarted"
        type="button"
        class="btn btn-secondary"
        @click.prevent="resetForm">
        {{ $t('upload.buttonReset') }}
      </button>
    </form>
  </section>

  <base-modal modal-id="Loader" :visible="submitCompleted">
    <template v-slot:title>
      <span v-if="hasErrors">{{ $t('upload.titleUploadError') }}</span>
      <span v-else>{{ $t('upload.titleUploadSuccess') }}</span>
    </template>

    <div class="mb-3" v-if="hasErrors">
      <ul class="errors">
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <div class="mb-3" v-else>
      <p>
        {{ $t('upload.messageUploadSuccess') }}
      </p>
    </div>

    <template v-slot:footer>
      <div class="col-12 text-center">
        <button type="button" class="btn btn-secondary" @click="confirmUpload">
          {{ $t('upload.buttonClose') }}
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script>
// import { bmecatUpload } from "../api/bmecatUpload";
import { bmecat } from "../api/api";
import BmecatUploadRequest from "../api/BmecatUploadRequest";
import BaseModal from "./ui/BaseModal.vue";

export default {
  components: {
    BaseModal,
  },

  inject: ['appsBaseUrl'],

  mounted() {
    console.debug('UploadFormComponent with apps baseURL: ' + this.appsBaseUrl);
  },

  data() {
    return {
      honeypotValue: "",
      email: "",
      file: null,
      gtc: true,
      newsletter: true,
      progress: 0,
      state: "PENDING",
      messages: [],
      errors: [],
      cancelUploadController: null,
    };
  },

  computed: {
    /**
     */
    submitCompleted() {
      return this.state === "SUBMIT_COMPLETED";
    },

    /**
     */
    submitStarted() {
      return this.state === "SUBMIT_STARTED";
    },

    /**
     */
    submitReady() {
      if (!this.file || this.file.length === 0) {
        return false;
      }
      if (!this.gtc) {
        return false;
      }
      if (this.email.trim() === "") {
        return false;
      }
      return this.state === "PENDING";
    },

    /**
     */
    hasErrors() {
      return this.errors.length > 0;
    },
  },

  methods: {
    /**
     * @param {*} event
     */
    onFileChange(event) {
      this.file = event.target.files[0] || null;
    },

    /**
     * @param {*} event
     */
    handleUploadProgress(event) {
      this.progress = Math.round(event.progress * 100);
    },

    /**
     *
     */
    cancleUpload() {
      if (!this.cancelUploadController) return;
      this.cancelUploadController.abort();
    },

    /**
     *
     */
    submitData() {
      if (this.honeypotValue.length > 0) {
        return false;
      }

      if (!this.submitReady) {
        return;
      }

      this.state = "SUBMIT_STARTED";

      this.cancelUploadController = new AbortController();

      const request = new BmecatUploadRequest(
        this.email,
        this.file,
        this.newsletter,
        this.gtc,
        this.$i18n.locale
      );

      const config = {
        onUploadProgress: this.handleUploadProgress,
        signal: this.cancelUploadController.signal,
        baseURL: this.appsBaseUrl
      };

      bmecat
        .upload(request, config)
        .then((response) => {
          if (response.data.status !== "success") {
            return;
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("ERROR WITH RESPONSE");

            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);

            if (error.response.data.errors) {
              this.addErrors(error.response.data.errors);
            } else {
              this.errors.push(error.response.data);
            }
          } else if (error.request) {
            console.log("ERROR NO RESPONSE");
            console.log(error.request);

            this.errors.push("Request Error.");
          } else {
            console.log("ERROR IN REQUEST");
            console.log(error.message);

            this.errors.push("Request Error.");
          }
        })
        .finally(() => {
          this.state = "SUBMIT_COMPLETED";
        });
    },

    addErrors(errors, context) {
      if (typeof errors === "object") {
        for (const id in errors) {
          if (typeof errors[id] === "object") {
            this.addErrors(errors[id], id);
          } else {
            this.errors.push(`${context}: ${errors[id]}`);
          }
        }
      } else {
        this.errors.push(errors);
      }
    },

    /**
     *
     */
    resetForm() {
      this.resetUpload();

      this.email = "";
      this.newsletter = true;
      this.gtc = true;
    },

    /**
     */
    resetUpload() {
      this.file = null;
      this.progress = 0;
      this.messages = [];
      this.errors = [];
      this.cancelUploadController = null;
      this.state = "PENDING";

      this.$refs.inputFile.value = null;
    },

    /**
     */
    confirmUpload() {
      this.resetUpload();
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}
ul.errors li {
  color: red;
}
</style>
