import apiClient from "../api";

const bmecatUploadUrl = "/api/bmecat-transformations/upload";

/**
 * @param {BmecatUploadRequest} request
 * @param {Array} config
 * @returns Promise
 */
export function upload(request, config) {
  return apiClient.post(bmecatUploadUrl, request.formData(), config);
}
