export default {
    de: {
        upload: {
            labelInputEmailHomepage: "E-Mail Homepage",
            labelInputEmail: "Ihre E-Mail Adresse",
            emailHelp: "Wir geben Ihre E-Mail Adresse nicht an Dritte weiter.",
            labelInputFile: "Wählen sie bitte Ihre zu transformierende BMEcat XML Datei aus.",
            labelNewsletter: "Newsletter abonnieren",
            labelGtc: "Datenschutzbestimmung akzeptieren",
            buttonStart: "Starten",
            buttonCancle: "Abbrechen",
            buttonReset: "Zurücksetzen",
            titleUploadError: "Fehler aufgetreten",
            titleUploadSuccess: "Upload erfolgreich",
            messageUploadSuccess: "Ihr Upload wurde erfolgreich abgeschlossen. Sie erhalten in kürze eine E-Mail mit dem Ergebnis Ihrer BMEcat Transformation.",
            buttonClose: "Schließen"
        }
        
    },
    en: {
        upload: {
            labelInputEmailHomepage: "Email homepage",
            labelInputEmail: "Your email address",
            emailHelp: "We do not pass on your email address to third parties.",
            labelInputFile: "Please select your BMEcat XML file to be transformed.",
            labelNewsletter: "Subscribe to Newsletter",
            labelGtc: "Accept data protection policy",
            buttonStart: "Start",
            buttonCancle: "Cancel",
            buttonReset: "Reset",
            titleUploadError: "Error occurred",
            titleUploadSuccess: "Upload successful",
            messageUploadSuccess: "Your upload has been completed successfully. You will shortly receive an email with the result of your BMEcat transformation.",
            buttonClose: "Close"
        }
    }
}