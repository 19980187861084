<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="title"></slot>
          </h5>
        </div>
        <div class="modal-body text-center">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      modal: null,
    };
  },
  
  props: {
    modalId: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    visible(visible) {
      if (visible) {
        this.show();
      } else {
        this.hide();
      }
    },
  },
  mounted() {
    this.modal = new Modal(`#${this.modalId}`);
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
  },
};
</script>
