<template>
  <upload-form></upload-form>
</template>

<script>
import UploadForm from "./components/UploadForm.vue";
export default {
  name: "App",
  
  props: {
    locale: String,
    appsBaseUrl: String
  },

  provide() {
    return {
      appsBaseUrl: this.appsBaseUrl
    };
  },

  components: {
    UploadForm
  },

  beforeMount() {
    console.debug(process.env);
    if (this.locale) {
      this.$i18n.locale = this.locale;
    }
  }
};
</script>

<style></style>
