/**
 * 
 */
export default class BmecatUploadRequest {
    /**
     * @param {string} email 
     * @param {File} bmecat 
     * @param {boolean} newsletter 
     * @param {boolean} gtc 
     * @param {string} locale
     */
    constructor(email, bmecat, newsletter, gtc, locale) {
        this.email = email;
        this.bmecat = bmecat;
        this.newsletter = newsletter;
        this.gtc = gtc;
        this.locale = locale;
    }

    formData() {
        const formData = new FormData();
  
        formData.append("email", this.email);
        formData.append("bmecat", this.bmecat);
        formData.append("newsletter", this.newsletter);
        formData.append("gtc", this.gtc);
        formData.append("locale", this.locale);

        return formData;
    }
}