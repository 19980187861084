import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'

const appElement = document.querySelector('#app');

const app = createApp(App, {...appElement.dataset});

app.use(i18n)

app.mount('#app')
